export function lazyLoadImages() {
    if ("IntersectionObserver" in window) {
        const lazyImages = [].slice.call(document.querySelectorAll("img.lazy"));

        let lazyImageObserver = new IntersectionObserver(function(entries, observer) {
            entries.forEach(function(entry) {
                if (entry.isIntersecting) {
                    let lazyImage = entry.target;
                    lazyImage.src = lazyImage.dataset.src;
                    lazyImage.classList.remove("lazy");
                    lazyImageObserver.unobserve(lazyImage);
                }
            });
        });

        lazyImages.forEach(function(lazyImage) {
            lazyImageObserver.observe(lazyImage);
        });
    } else {
        const lazyImages = [].slice.call(document.querySelectorAll("img.lazy"));
        lazyImages.forEach(function(image) {
            image.src = image.dataset.src;
            image.classList.remove("lazy");
        });
    }
}
