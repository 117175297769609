import(/* webpackChunkName: "fanalytics" */ '@fiizy/fanalytics')
import '../css/main.scss'
import hideLoading from '../../../common/scripts/hideLoading'
import tableOfContentsOnMobile from '../../../common/scripts/tableOfContents'
import isMobile from '../../../common/scripts/isMobile'
import loadMenu from '../../../common/scripts/loadMenu'
import cookieLaw from '../../../common/scripts/cookieLaw'
import {lazyLoadImages} from '../../../common/scripts/lazyLoad'
import {applyPolyfills, defineCustomElements} from '@fiizy/stylebook/loader'

applyPolyfills().then(() => {
  defineCustomElements()
})

if (document.location.href.includes('finzmo.com.br') || document.location.href.includes('finzmo-com-br')) {
  import(/* webpackChunkName: "finzmoComBr" */ '../css/themes/finzmoComBr.scss')
} else if (document.location.href.includes('finzmo')) {
  import(/* webpackChunkName: "finzmo" */ '../css/themes/finzmo.scss')
} else if (document.location.href.includes('solcredit.vn') || document.location.href.includes('solcredit-vn')) {
  import(/* webpackChunkName: "solcreditVn" */ '../css/themes/solcreditVn.scss')
} else if (document.location.href.includes('solcredito.co') || document.location.href.includes('solcredito-co')) {
  import(/* webpackChunkName: "solcreditCo" */ '../css/themes/solcreditCo.scss')
} else if (document.location.href.includes('solcredit')) {
  import(/* webpackChunkName: "solcredito" */ '../css/themes/solcredito.scss')
} else if (document.location.href.includes('prestamosmas')) {
  import(/* webpackChunkName: "prestamosmas" */ '../css/themes/prestamosmas.scss')
} else if (document.location.href.includes('credimon')) {
  import(/* webpackChunkName: "credimon" */ '../css/themes/credimon.scss')
} else if (document.location.href.includes('prestamon')) {
  import(/* webpackChunkName: "prestamon" */ '../css/themes/prestamon.scss')
} else if (document.location.href.includes('dineromon')) {
  import(/* webpackChunkName: "dineromon" */ '../css/themes/dineromon.scss')
} else if (document.location.href.includes('dinheiromon')) {
  import(/* webpackChunkName: "dinheiromon" */ '../css/themes/dinheiromon.scss')
} else if (document.location.href.includes('medimas')) {
  import(/* webpackChunkName: "medimas" */ '../css/themes/medimas.scss')
}

window.loadProduct = function loadProduct(loanType) {
  const urlSearchParams = new URLSearchParams(window.location.search)
  urlSearchParams.set('loanType', loanType)
  window.location.href = 'process?' + urlSearchParams.toString()
}

document.addEventListener('DOMContentLoaded', () => {
  loadMenu()

  if (isMobile()) {
    tableOfContentsOnMobile()
  }
  hideLoading()
  cookieLaw()
  lazyLoadImages()
})

