function loadMenu() {
    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
    const $navbarMenu = document.querySelector('#navbar');

    if ($navbarBurgers.length > 0) {
        $navbarBurgers.forEach(el => {
            const target = el.dataset.target;
            const $target = document.getElementById(target);
            el.addEventListener('click', () => {
                el.classList.toggle('is-active');
                $target.classList.toggle('is-active');
            });
            $navbarMenu.addEventListener('click', () => {
                el.classList.toggle('is-active');
                $target.classList.toggle('is-active');
            })
        });
    }
}

export default loadMenu
