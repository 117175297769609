import {eventbus, eventBusEvents} from "@fiizy/utils";

const bus = eventbus(window.eventChannel || 'c432556660722ff93cefb5a932e550b7');
const {appLoaded} = eventBusEvents;

function hideLoading() {
    bus.on(appLoaded, () => {
        const loadingElement = document.querySelector('#wave');
        if (loadingElement) {
            loadingElement.style.display = 'none'
        }
    })
}

export default hideLoading
