const cookieBlock = document.getElementById('cookie-consent');

function cookieLaw() {
    if (!Element.prototype.matches) {
        Element.prototype.matches = Element.prototype.msMatchesSelector ||
            Element.prototype.webkitMatchesSelector;
    }

    if (cookieBlock) {
        if (!document.cookie.match(/^(.*;)?\s*cookiesDirective\s*=\s*[^;]+(.*)?$/)) {
            cookieBlock.classList.add('slide-in')
            cookieBlock.style.display = 'block';
        }

        if (cookieBlock.style.display === 'block') {
            const cookieAgreeButton = document.getElementById('close-cookie-consent');
            if (cookieAgreeButton) {
                document.getElementById('close-cookie-consent').addEventListener("click", function (event) {
                    event.preventDefault();
                    agreeCookies();
                }, false);
            }
        }
    }
}

export function agreeCookies() {
    setCookie('cookiesDirective', true, 365);
    if (cookieBlock && cookieBlock.parentNode) {
        cookieBlock.classList.remove('slide-in')
        cookieBlock.classList.add('slide-out')
    }
}

export function setCookie(name, value, days) {
    let expires;
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = `; expires=${date.toGMTString()}`;
    } else {
        expires = '';
    }
    document.cookie = `${name}=${value}${expires}; path=/`;
}

export default cookieLaw
