function tableOfContentsOnMobile() {
    function eventHandler(event) {
        const element = document.getElementById(event.detail);

        element.scrollIntoView();
    }

    if (!Element.prototype.matches) {
        Element.prototype.matches = Element.prototype.msMatchesSelector ||
            Element.prototype.webkitMatchesSelector;
    }

    const element = document.getElementById('table-of-contents');

    if (element) {
        let menuOptions = [];
        for (let menuOption in fiMenuItems) {
            const option = fiMenuItems[menuOption];
            menuOptions.push({
                key: option, value: option, label: option
            })
        }

        element.innerHTML = `<fi-select label="Table of contents" type="markup"></fi-select>`;
        element.querySelector('fi-select').options = menuOptions;
        const select = element.querySelector('fi-select');
        select.addEventListener('fiInput', evt => {
            eventHandler(evt);
            element.querySelector('fi-select').validationClass = 'valid'
        })
    }
}

export default tableOfContentsOnMobile
